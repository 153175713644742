$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navmenu-button").click(function(){
        $(".js-header").toggleClass("navOpen");
        $(".js-navmenu, .js-navmenu-overlay").toggleClass("active");
        $("html").toggleClass("freeze");
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderIntro
    // *
    // *
    var $sliderIntro = $(".js-sliderintro");

    if ($sliderIntro.length) {
        $sliderIntro.each(function(){
            var $sliderIntroImages = $(this).find(".js-sliderintro-images"),
                $sliderIntroContent = $(this).find(".js-sliderintro-content");
                total = $sliderIntroContent.find(".sliderIntro__content").length;

            $sliderIntroImages.slick({
                infinite: true,
                fade: false,
                speed: 1000,
                autoplay: true,
                autoplaySpeed: 4000,
                dots: true,
                arrows: true,
                prevArrow: $(this).find(".js-sliderintro-prev"),
                nextArrow: $(this).find(".js-sliderintro-next"),
                asNavFor: $sliderIntroContent,
                waitForAnimate: false,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            dots: false,
                        }
                    }
                ]
            });

            $sliderIntroContent.slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                speed: 1000,
                waitForAnimate: false,
                asNavFor: $sliderIntroImages,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            adaptiveHeight: true,
                        }
                    }
                ]
            });

            // set total
            $(this).find(".js-sliderintro-total").html(total);

            // set current count
            $sliderIntroImages.on('beforeChange', function(event, slick, currentSlide, nextSlide){
                $sliderIntro.find(".js-sliderintro-current").html((nextSlide + 1));
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion, .js-accordion-alt").click(function(e) {
        if($(e.target).is($("a"))) {
            return
        };
        $(this).toggleClass("active").find(".js-accordion-content").slideToggle();
    })

    $(".js-accordion:first-child").trigger("click");



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * factBox
    // *
    // *
    $('.js-factbox-wrapper').masonry({
        // options
        itemSelector: '.js-factbox',
        // columnWidth: '.js-image-teaser',
        percentPosition: true,
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter jobs
    // *
    // *
    if ($(".js-filter-elements").length) {
        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-filter-element'
            },
            load: {
                filter: 'all'
            }
        });
    }

    $(".js-filter-item").click(function() {
        $(this).toggleClass("active").parent().siblings().find(".js-filter-item").removeClass("active");
    })

    $(".js-filter-close, .js-filter-setting").click(function() {
        $(".js-filter-item").removeClass("active");
    })


});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header  + scrollUp
// *
// *
$(window).on("load scroll", function(){
    if($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
        $(".js-scrollup").addClass("active");
    }else {
        $(".js-header").removeClass("sticky");
        $(".js-scrollup").removeClass("active");
    }
});


// * * * * * * * * * * * * * * * * * * * * * * * * *
// * invert Logo
// *
// *
$(window).on("load resize scroll", function() {
    var invertLogoPos = $(".js-invert-logo").position().top;

    $(".js-invert-section").each(function() {
        var sectionStart = $(this).offset().top - $(window).scrollTop(),
            sectionEnd = $(this).offset().top + $(this).outerHeight() - $(window).scrollTop();

        if (invertLogoPos > sectionStart && invertLogoPos < sectionEnd) {
            $(".js-invert-logo").addClass("inverted");
        }else {
            $(".js-invert-logo").removeClass("inverted");
        }
    });
});
